import dk from "@norsktest/dkdj";

export class PageRating extends dk.Widget {
    constructor(...args) {
        const props = Object.assign(...args);
        super({
            ajax_url: '/pagerating/',
        }, props);
        this.cmspage_id = props.cmspage_id;
        this.page_url = props.page_url;
    }

    construct() {
        this.upvote_btn = this.widget('button.pagerating-upvote');
        this.downvote_btn = this.widget('button.pagerating-downvote');
        this.pagerating_btns = this.widget('.pagerating-vote');
        this.feedback = this.widget('.pagerating-feedback');
    }

    handlers() {
        this.upvote_btn.on('click', e => {
            this.upvote();
        });

        this.downvote_btn.on('click', e => {
            this.downvote();
        });
    }

    upvote() {
        this.upvote_btn.css('color', 'green');
        this.vote(1);
    }

    downvote() {
        this.downvote_btn.css('color', 'red');
        this.vote(-1);
    }

    vote(val) {
        this.start_busy();

        dk.json({
            url: this.ajax_url,
            data: {
                vote: val,
                cmspage_id: this.cmspage_id,
                page_url: this.page_url
            },
            success: data => {
                const msg = data.message;
                if (!data.ok) {
                    this.display_error(msg);
                } else {
                    this.display_msg(msg);
                }
                this.end_busy();
            }
        });
    }


    start_busy() {
        this.pagerating_btns.prop('disabled', true);
    }

    display_error(msg) {
        this.display_msg(msg);
        this.feedback.addClass('errordescr');
    }

    display_msg(msg) {
        this.feedback.html(msg);
    }

    end_busy() {
        this.pagerating_btns.addClass('pagerating-finished')
    }

}
